import type { themeStyleType, themeType } from '~/features/auth/store/state'

export const modes = [
  {
    label: 'Light',
    type: 'light',
    icon: 'fa fa-sun'
  },
  {
    label: 'Dark',
    type: 'dark',
    icon: 'fa fa-moon'
  }
] as themeType[]

export const styles = [
  {
    label: 'Neo',
    type: 'neo',
    icon: 'fal fa-robot'
  },
  {
    label: 'Chowey',
    type: 'chowey',
    icon: 'fal fa-paw'
  }
] as themeStyleType[]
